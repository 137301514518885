import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allFile(
          filter: {
            extension: { regex: "/(jpg)|(png)|(jpeg)/" }
            relativeDirectory: { regex: "/^work/" }
          }
        ) {
          edges {
            node {
              base
              relativePath
              childImageSharp {
                fluid(maxWidth: 1000) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      let sorted_images = data.allFile.edges
        .filter(edge => {
          return (
            ("/" + edge.node.relativePath).indexOf(props.path) > -1 &&
            /[0-9]{3}.[a-z]{3,4}$/g.test(edge.node.relativePath)
          )
        })
        .sort((a, b) => a.node.relativePath.localeCompare(b.node.relativePath))
        console.log('-------------')
        console.log(props)
        console.log(sorted_images)
      return <div className="image-wrap" style={{backgroundImage: `url(${sorted_images[0].node.childImageSharp.fluid.src})`}} ></div>
    }
  }
  />
)
