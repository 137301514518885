import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import WorkLink from "../components/work-link"

export const query = graphql`
  query WorkQuery {
    allWorkJson {
      edges {
        node {
          title
          path
        }
      }
    }
  }
`

const WorkPage = ({ data }) => (
  <Layout>
    <SEO title="Work" />
    <h1>Work</h1>
    <div id="work-projects">
      {data.allWorkJson.edges.map(({ node }, i) => {
        return (
          <div key={i} className="project">
            <Link to={node.path}>
              
                <WorkLink path={node.path} />
              
              <p>{node.title}</p>
            </Link>
          </div>
        )
      })}
    </div>
  </Layout>
)
export default WorkPage
